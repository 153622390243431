"use client";

import { type FormEvent, useState } from "react";

interface Props {
  disabled: boolean;
  outline: "border" | "unstyled";
  variant?: "default" | "transparent" | "square";
  value?: string;
}

export default function InputField({ disabled, value = "", outline, variant = "default" }: Props) {
  const [currentValue, setCurrentValue] = useState<string>(value);
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setCurrentValue(event.currentTarget.value.trimStart());
  };

  const variantStyle = `${variant !== "default" ? variant : ""}_input`;
  const outlineStyle =
    outline === "unstyled" ? "!border-none !active:border-none !outline-none" : "";

  return (
    <input
      className={`input ${outlineStyle} ${variantStyle}`}
      readOnly={disabled}
      value={currentValue}
      onChange={handleChange}
    />
  );
}
